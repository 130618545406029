import React from "react";
import PageTemplate from "../page-template";
import { Box } from "@mui/material";

//----------------------------------------//

interface Props {}

const Home: React.FC<Props> = ({}) => {
  // Hooks

  // Effect hooks

  // Handlers

  return (
    <PageTemplate
      id="home"
      title="Food Dynamic Back Office"
      subtitle="Herramientas para tu negocio"
    >
      <Box sx={{ px: 2, py: 2, maxWidth: "100vw" }}>
        <p>Bienvenido/a a Food Dynamic - Back Office.</p>
        <p>
          Selecciona una opción en el menú lateral para acceder a los datos de
          tu restaurante.
        </p>
      </Box>
    </PageTemplate>
  );
};

export default Home;
