import React from "react";
import "./page-template.css";

interface Props {
  id: string;
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
}

const PageTemplate: React.FC<Props> = ({ id, children }) => {
  return (
    <div id={id}>
      <main id="page-content">{children}</main>
    </div>
  );
};

export default PageTemplate;
