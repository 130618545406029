import React, { useState } from "react";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  LoadBusinessData,
  SetUserData,
  setSessionToken,
} from "../../business/ParametersController";
import { TryLogin, UserPinCheck } from "../../services/AuthServices";
import { Close } from "@mui/icons-material";
import { HTTP_R_CODE } from "../../services";

import "./styles.css";

//----------------------------------------//

interface Props {}

const Form = (
  login: string,
  setLogin: React.Dispatch<React.SetStateAction<string>>,
  password: string,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
  visiblePassword: boolean,
  setVisiblePassword: React.Dispatch<React.SetStateAction<boolean>>
) => (
  <Box
    sx={{
      py: 3,
      width: 280,
      display: "flex",
      flexDirection: "column",
      rowGap: 2,
      justifyContent: "center",
    }}
  >
    <TextField
      variant="standard"
      InputLabelProps={{ shrink: true }}
      label="Login"
      id="login"
      size="small"
      required
      value={login}
      onChange={(e) => setLogin(e.target.value)}
      sx={{ width: "100%" }}
    />
    <FormControl variant="outlined" size="small" required>
      <TextField
        variant="standard"
        label="Contraseña *"
        size="small"
        type={visiblePassword ? "text" : "password"}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisiblePassword((show) => !show)}
                edge="end"
              >
                {visiblePassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </FormControl>
  </Box>
);

const Login: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [multiuser, setMultiuser] = useState<boolean>(false);
  const [userList, setUserList] = useState<
    { id: number; name: string; role: "WORKER" | "MANAGER" | "OWNER" }[]
  >([]);
  const [userId, setUserId] = useState<string>("");
  const [pin, setPin] = useState<string>("");

  // useEffect(() => {
  //   if (getSessionToken() != "") navigate("home");
  // }, []);

  //>> Handlers
  const handleLogin = async () => {
    setLoading(true);
    TryLogin(login, password, remember).then((r) => {
      setLoading(false);
      if (!r || r.status != HTTP_R_CODE.OK) {
        setError("Error en el inicio de sesión");
        return;
      }

      LoadBusinessData(r.data.name, r.data.slug);
      setSessionToken(r.data.session_token, remember);

      if (r.data.users.length > 0) {
        setMultiuser(true);
        setUserList(r.data.users);
      } else {
        SetUserData(
          "null",
          "ADMIN"
        );
        navigate("home");
      }
    });
  };

  const handlePinCheck = async () => {
    if (pin.length < 4 || pin.length > 4)
      return setError("El PIN debe contener 4 dígitos");
    setLoading(true);
    UserPinCheck(userId, pin).then((r) => {
      setLoading(false);
      if (!r || r.status != HTTP_R_CODE.OK) {
        setError("Error en el inicio de sesión");
        return;
      }
      SetUserData(
        userId,
        userList.find((u) => u.id.toString() === userId)?.name || ""
      );
      navigate("home");
    });
  };

  return (
    <div style={{}} className="pattern-bkg">
      <div
        style={{
          padding: "6% 6%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "0px auto",
          background: "rgb( 255, 255, 255, 0.933 )",
          boxShadow:
            "rgb(102, 102, 102) 2px 2px 4px, rgb(102, 102, 102) -2px -2px 8px",
        }}
      >
        <img src="/assets/logo_fds.png" width={200} alt="Food Dynamic" />
        <p
          style={{
            fontStyle: "italic",
            margin: 0,
            fontSize: "1rem",
            color: "#9a9a9a",
            marginBottom: 8,
          }}
        >
          Back Office
        </p>
        <Collapse in={!multiuser}>
          <div>
            {Form(
              login,
              setLogin,
              password,
              setPassword,
              visiblePassword,
              setVisiblePassword
            )}
            <Button
              type="submit"
              variant="contained"
              onClick={handleLogin}
              disabled={!login || !password || loading}
              sx={{ width: "100%", textTransform: "none" }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Acceder"
              )}
            </Button>
          </div>
        </Collapse>
        <Collapse in={multiuser}>
          <Box
            sx={{
              py: 3,
              width: 280,
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
              justifyContent: "center",
            }}
          >
            <FormControl variant="outlined" required size="small" id="role">
              <InputLabel id="role-label">Usuario</InputLabel>
              <Select
                id="user-id"
                labelId="user-label"
                label="Usuario"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                color="primary"
                sx={{
                  borderRadius: 0,
                }}
                MenuProps={{
                  MenuListProps: { style: { background: "#fafafa" } },
                }}
              >
                {userList.map((ur) => (
                  <MenuItem key={`user-id-${ur.id}`} value={ur.id.toString()}>
                    {ur.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" size="small" required id="pin">
              <TextField
                variant="standard"
                label="Pin *"
                size="small"
                inputMode="numeric"
                type={visiblePassword ? "text" : "password"}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle pin visibility"
                        onClick={() => setVisiblePassword((show) => !show)}
                        edge="end"
                      >
                        {visiblePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </FormControl>
          </Box>
          <Button
            type="submit"
            variant="contained"
            onClick={handlePinCheck}
            disabled={!userId || !pin || loading}
            sx={{ width: "100%", textTransform: "none" }}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Acceder"
            )}
          </Button>
        </Collapse>
        <Collapse in={error !== ""} collapsedSize={0}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError("")}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 0, px: 0 }}
          >
            {error}
          </Alert>
        </Collapse>
      </div>
    </div>
  );
};

export default Login;
