import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  AttachMoney,
  AutoGraph,
  BarChart,
  ControlCamera,
  Dashboard,
  ExpandMore,
  History,
  Key,
  ListAlt,
  Lock,
  Logout,
  Numbers,
  Person,
  PointOfSale,
  ReceiptLongOutlined,
  TroubleshootOutlined,
} from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import {
  P,
  ReadParameter,
  UnloadBusinessData,
  UnloadParameters,
} from "../../business/ParametersController";
import "./styles.css";
import { APP_VERSION } from "../../constants/Data";

const pages = [
  { name: "Panel general", slug: "dashboard", icon: <Dashboard /> },
  {
    name: "Análisis",
    slug: "stats-main",
    icon: <TroubleshootOutlined />,
    subitems: [
      { name: "Estadísticas", slug: "stats", icon: <BarChart /> },
      { name: "Ventas", slug: "sales", icon: <PointOfSale /> },
      {
        name: "Análisis de costes",
        slug: "cost-explorer",
        icon: <AttachMoney />,
      },
      { name: "Simulador", slug: "simulator", icon: <AutoGraph /> },
    ],
  },
  {
    name: "Personal",
    slug: "staff-main",
    icon: <Person />,
    subitems: [
      { name: "Usuarios", slug: "staff", icon: <ListAlt /> },
      { name: "Permisos", slug: "staff/roles", icon: <Lock /> },
      { name: "Eventos", slug: "staff/audit", icon: <ControlCamera /> },
      { name: "Turnos", slug: "staff/schedules", icon: <History /> },
    ],
  },
  // {
  //   name: "Facturación",
  //   slug: "billing-main",
  //   icon: <ReceiptLongOutlined />,
  //   subitems: [
  //     { name: "Series", slug: "billing/series", icon: <Numbers /> },
  //     {
  //       name: "Facturas",
  //       slug: "billing/invoices",
  //       icon: <ReceiptLongOutlined />,
  //     },
  //   ],
  // },
  { name: "Seguridad", slug: "security", icon: <Key /> },
];
const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement | null;
}

export default function ResponsiveAppBar(props: Props) {
  const { window } = props;
  // History
  const navigate = useNavigate();
  const location = useLocation();

  // Menu handling
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(
    pages.find((p) => location.pathname.includes(p.slug))?.slug || ""
  );
  const [expandedNodes, setExpandedNodes] = React.useState<
    { slug: string; expanded: boolean }[]
  >(
    pages
      .filter((p) => p.subitems && p.subitems.length > 0)
      .map((f) => {
        return { slug: f.slug, expanded: false, selected: false };
      })
  );

  const handleLogout = () => {
    UnloadParameters();
    UnloadBusinessData();
    setMobileOpen(false);
    navigate("");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (slug: string) => {
    setSelectedIndex(slug);
    if (mobileOpen) handleDrawerToggle();
    navigate(slug);
  };

  const toggleNodeExpand = (slug: string) => {
    let aux = [...expandedNodes];
    const idx = aux.findIndex((x) => x.slug === slug);

    if (idx > -1) {
      aux[idx].expanded = !aux[idx].expanded;
      setExpandedNodes(aux);
    }
  };

  const handleListSubItemClick = (slug: string) => {
    setSelectedIndex(slug);
    if (mobileOpen) handleDrawerToggle();
    navigate(slug);
  };

  const drawer = (
    <div
      className="thin-scrollbar"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        maxHeight: "calc(100vh - 170px)",
      }}
    >
      <List sx={{ flex: 1 }}>
        {pages.map(({ icon, name, slug, subitems }, n) => (
          <span key={slug}>
            <ListItemButton
              onClick={() => {
                if (subitems && subitems.length > 0) toggleNodeExpand(slug);
                else handleListItemClick(slug);
              }}
              sx={{
                borderLeft:
                  selectedIndex === slug
                    ? "4px solid rgb(220 38 38)"
                    : "initial",
              }}
              selected={selectedIndex === slug}
            >
              <ListItemIcon
                sx={{
                  color: selectedIndex === slug ? "rgb(220 38 38)" : "#f0f0f0",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: selectedIndex === slug ? "rgb(220 38 38)" : "#f0f0f0",
                }}
                primary={name}
              />
              {subitems && subitems.length && (
                <ExpandMore
                  sx={{
                    color:
                      selectedIndex === slug ? "rgb(220 38 38)" : "#f0f0f0",
                    transition: "all 0.1s ease-in-out",
                    transform: expandedNodes.find((en) => en.slug === slug)
                      ?.expanded
                      ? "rotate(180deg)"
                      : undefined,
                  }}
                />
              )}
            </ListItemButton>
            {subitems && subitems.length > 0 && (
              <Collapse
                in={expandedNodes.find((en) => en.slug === slug)?.expanded}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {subitems.map((si) => (
                    <ListItemButton
                      key={`${slug}-subitem-${si.slug}`}
                      sx={{
                        pl: 4,
                        borderLeft:
                          selectedIndex === si.slug
                            ? "4px solid rgb(220 38 38)"
                            : "initial",
                      }}
                      onClick={() => {
                        handleListSubItemClick(si.slug);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            selectedIndex === si.slug
                              ? "rgb(220 38 38)"
                              : "#f0f0f0",
                        }}
                      >
                        {si.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={si.name}
                        sx={{
                          color:
                            selectedIndex === si.slug
                              ? "rgb(220 38 38)"
                              : "#f0f0f0",
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </span>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiListItemButton: {
            defaultProps: {
              // disableTouchRipple: true,
              // color: "#f0f0f0",
            },
          },
        },
        palette: {
          // mode: "dark",
          // primary: { main: "rgb(102, 157, 246)" },
          primary: red,
          background: { paper: "#323232", default: "#ffffff" },
          text: {
            // primary: "#fafafa",
            // secondary: "#fafafa",
            // disabled: "#909090",
          },
        },
      })}
    >
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="inherit"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: location.pathname === "/" ? "none" : "initial",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" }, color: "#f0f0f0" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ color: "#f0f0f0" }}>
              {pages.find((p) => p.slug === selectedIndex)?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            display: location.pathname === "/" ? "none" : "initial",
          }}
          aria-label="main menu"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <img
              src="/assets/white_full_logo.png"
              width={150}
              alt="Food Dynamic"
              style={{ margin: "0.725rem auto" }}
            />
            {drawer}
            <p
              style={{
                color: "#aaaaaa66",
                textAlign: "center",
                marginBottom: 4,
                marginTop: 4,
                fontFamily: "monospace",
                lineHeight: 1,
              }}
            >
              {APP_VERSION}
              <div style={{ height: 0 }} />
              {ReadParameter(P.name)} - {ReadParameter(P.user_name)}
            </p>
            <Divider />
            <ListItem
              sx={{ color: "#afafaf" }}
              disablePadding
              onClick={handleLogout}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "#afafaf" }}>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={"Cerrar sesión"} />
              </ListItemButton>
            </ListItem>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <img
              src="/assets/white_full_logo.png"
              width={150}
              alt="Food Dynamic"
              style={{ margin: "0.725rem auto" }}
            />
            {drawer}
            <p
              style={{
                color: "#aaaaaa66",
                textAlign: "center",
                marginBottom: 4,
                marginTop: 4,
                fontFamily: "monospace",
                lineHeight: 1,
              }}
            >
              {APP_VERSION}
              <div style={{ height: 0 }} />
              {ReadParameter(P.name)} - {ReadParameter(P.user_name)}
            </p>
            <Divider />
            <ListItem
              sx={{ color: "#afafaf" }}
              disablePadding
              onClick={handleLogout}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "#afafaf" }}>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={"Cerrar sesión"} />
              </ListItemButton>
            </ListItem>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar
            sx={{ display: location.pathname === "/" ? "none" : "block" }}
          />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
